import { useStaticQuery } from "gatsby"
import React from "react"
import ArticleCards from "../ArticleCards/ArticleCards"

const GhostPostFetcher = ({ tag }) => {
  const data = useStaticQuery(graphql`
    query test {
      allGhostPost {
        edges {
          node {
            slug
            title
            url
            feature_image
            tags {
              slug
            }
            excerpt
          }
        }
      }
    }
  `)

  const findArticlesMatchingTag = () => {
    try {
      // console.log({ tag })
      // console.log({ data })
      if (data.allGhostPost.edges.length > 0) {
        // console.log("111")
        let articles = []
        data.allGhostPost.edges.map((n, index) => {
          if (n.node.tags.length > 0) {
            let result = n.node.tags.filter(i => i.slug === tag)
            if (result && result.length > 0) {
              articles.push(n.node)
            }
            // n.node.tags.map((tagItem, index, n) => {
            //   if (tagItem.slug === tag) {
            //     console.log("match")
            //     articles.push(n)
            //   }
            // })
          }
        })
        // console.log({ articles })
        return articles
      }
    } catch (error) {}
  }

  const displayArticleCards = () => {
    try {
      let a = findArticlesMatchingTag()
      if (a && a.length > 0) {
        return (
          <>
            <p className="pat-section-header">Related Articles</p>
            <div className="pat-destinations-nearby-container">
              {a.map((item, index) => {
                return (
                  <ArticleCards
                    imageSrc={item.feature_image}
                    heading={item.title}
                    extract={item.excerpt}
                    postUrl={item.url}
                  />
                )
              })}
            </div>
          </>
        )
      }
    } catch (error) {}
  }

  return <div>{displayArticleCards()}</div>
}

export default GhostPostFetcher
