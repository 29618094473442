import { graphql } from "gatsby"
import { navigate } from "gatsby"
import React, { useEffect, useContext } from "react"
import "../styles/plan-a-trip.scss"
import Async from "react-async"
import { shuffle, titleCase } from "../utility/general"
import { RichText } from "prismic-reactjs"
import Layout from "../components/layout"
import algoliasearch from "algoliasearch/lite"
import climate from "../images/climate.png"
import plan from "../images/plan.png"
import select from "../images/select.png"
import tree from "../images/tree.png"
import wiggle from "../images/wiggle.svg"
import todo from "../images/todo.png"
import food from "../images/food.png"
import transportation from "../images/transportation.png"
import weather from "../images/weather.png"
import chat from "../images/chat.png"
import happy from "../images/happy.png"
import paris from "../images/paris.jpg"
import accommodation from "../images/accommodation.png"
import work from "../images/working-designer.png"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"
// import "swiper/css"
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectHits,
  connectSearchBox,
  PoweredBy,
  RatingMenu,
  RefinementList,
  HitsPerPage,
  connectHitsPerPage,
  Pagination,
  connectPagination,
  connectRefinementList,
  Highlight,
  Configure,
  connectPoweredBy,
} from "react-instantsearch-dom"
import PATDesignerCard from "../components/DesignerCards/PATDesignerCard"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slider,
  Slide,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { AuthUserContext } from "../components/session"
import { FirebaseContext } from "../components/firebase"
import { isMetaProperty } from "@babel/types"
import { countryListValueLabel } from "../constants/data/general"
import GhostPostFetcher from "../components/GhostPostFetcher/GhostPostFetcher"
import SEO from "../components/seo"

let context = ""
const getFeaturedDesigners = async ctx => {
  if (context) {
    console.log(titleCase(ctx.ctx))
    let cCode = countryListValueLabel.find(
      item => item.value.toLowerCase() === ctx.ctx.toLowerCase()
    )
    let dCollection = context.firestore().collection("designers")
    console.log({ cCode })
    const snapshot = await dCollection
      .where("countriesForItineraries", "array-contains", {
        label: `${cCode.value}`,
        value: `${cCode.value}`,
      })
      .where("available", "==", true)
      .limit(10)
      .get()
    let a = []
    snapshot.forEach(doc => {
      a.push(doc.data())
    })

    // console.log({ a })
    // let b = shuffle(a)
    // console.log({ b })
    return shuffle(a)
  }
}

const getTagsStrippedText = t => {
  try {
    return t.replace(/<[^>]+>/g, "")
  } catch (err) {}
}
const getResponsiveCount = sWidth => {
  if (sWidth < 640) return 1
  if (sWidth < 1024) return 2
  if (sWidth < 1254) return 3
  if (sWidth < 1600) return 4
  if (sWidth > 1600) return 5
}

const FetchAsyncDesigners = ({
  dbContext,
  destination,
  destination_country,
}) => {
  context = dbContext
  const screenWidth = useWindowWidth()
  // console.log({ dbContext })
  return (
    <Async promiseFn={getFeaturedDesigners} ctx={destination_country}>
      {({ data, error, isPending }) => {
        if (isPending) return "Loading..."
        if (error) return `Something went wrong: ${error.message}`
        if (data.length > 0) {
          return (
            <div className="pta-travel-designers-container" style={{}}>
              <p
                style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Featured Trip Designers for {destination}
              </p>
              <CarouselProvider
                naturalSlideWidth={220}
                // naturalSlideHeight={125}
                totalSlides={5}
                isPlaying={false}
                interval={10000}
                infinite={true}
                visibleSlides={getResponsiveCount(screenWidth)}
                isIntrinsicHeight={true}
                currentSlide={0}
                // naturalSlideWidth={275}
                className="nearby-destination-carousel"
              >
                <Slider style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  {/* <div style={{ display: "flex" }}> */}
                  {data.map((item, index) => {
                    return index > 4 ? (
                      ""
                    ) : (
                      <Slide>
                        <PATDesignerCard
                          id={item.uid}
                          key={index}
                          backgroundImage="https://blog.voyayge.com/content/images/2021/02/pablo-fierro-zOXUvQ3Xo3s-unsplash.jpg"
                          profileImage={item.userProfileImage}
                          name={`${item.firstName} ${item.lastName}`}
                          text={
                            getTagsStrippedText(item.aboutSkills) === ""
                              ? getTagsStrippedText(item.aboutIntroduction)
                              : getTagsStrippedText(item.aboutSkills)
                          }
                        />
                      </Slide>
                    )
                  })}
                  {/* </div> */}
                </Slider>
                <ButtonBack className="button is-warning pat-designers-back-button">
                  <i className="far fa-angle-left fa-lg"></i>
                </ButtonBack>
                <ButtonNext className="button is-warning pat-designers-next-button">
                  <i className="far fa-angle-right fa-lg"></i>
                </ButtonNext>
              </CarouselProvider>
            </div>
          )
        }
        return null
      }}
    </Async>
  )
}

const PlanATripBase = ({ state }) => {
  const authContext = useContext(AuthUserContext)
  const dbContext = React.useContext(FirebaseContext)
  const screenWidth = useWindowWidth()
  // console.log({ screenWidth })
  useEffect(() => {
    // console.log({ state })
  }, [state])

  useEffect(() => {
    console.log({ dbContext })

    return () => {}
  }, [dbContext])
  const searchClient = algoliasearch(
    `${process.env.ALGOLIA_SEARCH_APP_ID}`,
    `${process.env.ALGOLIA_SEARCH_API_KEY}`
  )
  let destination_country = state.data.allPrismicPlanATripTemplate.edges[0].node.data.destination_country.replace(
    /-/g,
    " "
  )
  // console.log({ destination_country })
  let pageTitle = titleCase(
    state.data.allPrismicPlanATripTemplate.edges[0].node.data.page_title.text
  )
  let destinationInfo =
    state.data.allPrismicPlanATripTemplate.edges[0].node.data.destination_info
      .raw

  const getUID = () => {
    try {
      let uid = state.data.allPrismicPlanATripTemplate.edges[0].node.uid
      return uid
    } catch (error) {
      return ""
    }
  }

  const returnSliceIndex = (sliceList, sliceType) => {
    try {
      if (sliceList) {
        return sliceList.findIndex(item => item.slice_type === sliceType)
      }
    } catch (error) {}
  }

  const showTopDestination = () => {
    try {
      if (
        state.data.allPrismicPlanATripTemplate.edges[0].node.data
          .destination_type === "City"
      )
        return false
      else return true
    } catch (error) {
      return false
    }
  }

  let thingsToDo = (function() {
    try {
      let rIndex = returnSliceIndex(
        state.data.allPrismicPlanATripTemplate.edges[0].node.data.body,
        "top_things_to_do"
      )
      if (rIndex != -1)
        return state.data.allPrismicPlanATripTemplate.edges[0].node.data.body[
          rIndex
        ].items
      else return []
    } catch (err) {}
  })()

  let bodyItems = (function() {
    try {
      return state.data.allPrismicPlanATripTemplate.edges[0].node.data.body
    } catch (err) {}
  })()

  let destinationsNearby = (function() {
    try {
      let rIndex = returnSliceIndex(
        state.data.allPrismicPlanATripTemplate.edges[0].node.data.body,
        "destinations_nearby"
      )
      if (rIndex != -1)
        return state.data.allPrismicPlanATripTemplate.edges[0].node.data.body[
          rIndex
        ].items
      else return []
    } catch (err) {}
  })()

  let topDestinations = (function() {
    try {
      let rIndex = returnSliceIndex(
        state.data.allPrismicPlanATripTemplate.edges[0].node.data.body,
        "top_destinations"
      )
      if (rIndex != -1)
        return state.data.allPrismicPlanATripTemplate.edges[0].node.data.body[
          rIndex
        ].items
      else return []
    } catch (err) {}
  })()
  let heroImageDetails = (function() {
    try {
      // console.log(
      //   state.data.allPrismicPlanATripTemplate.edges[0].node.data.hero_image
      // )
      return state.data.allPrismicPlanATripTemplate.edges[0].node.data
        .hero_image
    } catch (err) {}
  })()
  const onSelect = () => {
    console.log("onSelect Clicked")
  }

  const showThingsTodo = () => {
    try {
      if (thingsToDo.length > 0) {
        return (
          <>
            <p style={{ fontSize: "24px", fontWeight: "600" }}>
              Top things to do in {pageTitle}
            </p>
            {thingsToDo.map((item, index) => (
              <div key={index} className="pat-things-to-do-item">
                <div className="pat-things-to-do-item-image">
                  <LazyLoadImage
                    src={item.thing_to_do_image.url}
                    alt={item.thing_to_do_image.alt}
                    effect="blur"
                  ></LazyLoadImage>
                </div>
                <div className="pat-things-to-do-item-data">
                  <div className="pat-things-to-do-item-title">
                    {item.to_do_title.text}
                  </div>
                  <div className="pat-things-to-do-item-details">
                    <RichText render={item.to_do_description.raw} />
                  </div>
                </div>
              </div>
            ))}{" "}
          </>
        )
      }
    } catch (error) {}
  }
  const showDestinations = () => {
    try {
      if (topDestinations.length > 0) {
        return (
          <>
            <p className="pat-section-header">Destinations in {pageTitle}</p>
            <div className="pat-destinations-nearby-container">
              {topDestinations.map((item, index) => {
                if (item) {
                  return (
                    <a
                      className="pat-destinations-nearby"
                      href={item.destination_url.document.url}
                    >
                      <div className="pat-destinations-nearby-image">
                        <img
                          src={item.destination_image.url}
                          alt={item.destination_image.alt}
                        ></img>
                      </div>
                      <div className="pat-destinations-nearby-data">
                        <div className="pat-things-to-do-item-title">
                          {item.destination_name.text}
                        </div>
                        {/* <div className="pat-things-to-do-item-details">
                  {item.destination_url.document.url}
                </div> */}
                      </div>
                    </a>
                  )
                }
              })}
            </div>
          </>
        )
      }
    } catch (error) {}
  }
  const showDestinationsNearbyCarousel = () => {
    try {
      if (destinationsNearby.length > 0) {
        return (
          <>
            <p className="pat-section-header">Nearby Destinations</p>
            <div className="pat-destinations-nearby-container">
              <CarouselProvider
                naturalSlideWidth={330}
                // naturalSlideHeight={125}
                totalSlides={destinationsNearby.length}
                isPlaying={false}
                interval={10000}
                infinite={true}
                visibleSlides={1}
                isIntrinsicHeight={true}
                currentSlide={0}
                // naturalSlideWidth={275}
                className="nearby-destination-carousel"
              >
                <Slider>
                  {destinationsNearby.map((item, index) => {
                    if (item) {
                      return (
                        <Slide index={index} key={index}>
                          <a
                            className="pat-destinations-nearby"
                            href={item.destination_url.document.url}
                          >
                            <div className="pat-destinations-nearby-image">
                              <LazyLoadImage
                                src={item.destination_image.url}
                                alt={item.destination_image.alt}
                                effect="blur"
                              ></LazyLoadImage>
                            </div>
                            <div className="pat-destinations-nearby-data">
                              <div className="pat-things-to-do-item-title">
                                {item.destination_name.text}
                              </div>
                              {/* <div className="pat-things-to-do-item-details">
                  {item.destination_url.document.url}
                </div> */}
                            </div>
                          </a>
                        </Slide>
                      )
                    }
                  })}
                </Slider>
                <ButtonBack className="button is-warning pat-destinations-back-button">
                  <i className="far fa-angle-left fa-lg"></i>
                </ButtonBack>
                <ButtonNext className="button is-warning pat-destinations-next-button">
                  <i className="far fa-angle-right fa-lg"></i>
                </ButtonNext>
              </CarouselProvider>
            </div>
          </>
        )
      }
    } catch (error) {}
  }
  const showDestinationsNearby = () => {
    try {
      if (destinationsNearby.length > 0) {
        return (
          <>
            <p className="pat-section-header">Nearby Destinations</p>
            <div className="pat-destinations-nearby-container">
              {destinationsNearby.map((item, index) => {
                if (item) {
                  return (
                    <a
                      className="pat-destinations-nearby"
                      href={item.destination_url.document.url}
                      key={index}
                    >
                      <div className="pat-destinations-nearby-image">
                        <img
                          src={item.destination_image.url}
                          alt={item.destination_image.alt}
                        ></img>
                      </div>
                      <div className="pat-destinations-nearby-data">
                        <div className="pat-things-to-do-item-title">
                          {item.destination_name.text}
                        </div>
                        {/* <div className="pat-things-to-do-item-details">
                    {item.destination_url.document.url}
                  </div> */}
                      </div>
                    </a>
                  )
                }
              })}
            </div>
          </>
        )
      }
    } catch (error) {}
  }

  const showSliceType = sType => {
    try {
      if (bodyItems.length > 0) {
        var itemIndex = bodyItems.findIndex(item => item.slice_type === sType)
        if (itemIndex === -1) {
          if (sType === "top_things_to_do") {
            return (
              <div className="pta-things-to-do-container">
                {showThingsTodo()}
              </div>
            )
          }
          if (sType === "destinations_nearby") {
            return (
              <div className="pta-things-to-do-container">
                {" "}
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                  }}
                >
                  Destinations Nearby
                </p>
                destination
              </div>
            )
          }
        }
      }
    } catch (error) {}
  }
  const getTagsStrippedText = t => {
    try {
      return t.replace(/<[^>]+>/g, "")
    } catch (err) {}
  }

  const showDesigners = ({ hits }) => {
    return hits.map((item, index) => {
      if (item.doNotShow) return ""
      else
        return (
          <div className="" style={{}}>
            <PATDesignerCard
              id={item.uid}
              key={item.uid}
              // backgroundImage="https://blog.voyayge.com/content/images/2021/02/pablo-fierro-zOXUvQ3Xo3s-unsplash.jpg"
              profileImage={item.userProfileImage}
              name={`${item.firstName} ${item.lastName}`}
              text={
                getTagsStrippedText(item.aboutSkills) === ""
                  ? getTagsStrippedText(item.aboutIntroduction)
                  : getTagsStrippedText(item.aboutSkills)
              }
            />
            {/* <DesignerCardNew key={index} hit={item} onSelect={onSelect} /> */}
          </div>
        )
    })
  }
  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => {
    // return the DOM output

    return nbPages > 1 ? (
      <nav
        className="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        <a
          className="pagination-previous"
          onClick={event => {
            event.preventDefault()
            if (currentRefinement > 1) refine(currentRefinement - 1)
          }}
        >
          Previous
        </a>
        <a
          className="pagination-next"
          onClick={event => {
            event.preventDefault()
            if (currentRefinement < nbPages) refine(currentRefinement + 1)
          }}
        >
          Next page
        </a>
        {/* <ul className="pagination-list">
          {getPagingRange(currentRefinement, {
            min: 1,
            total: nbPages,
            length: 5,
          }).map((i, index) => {
            const page = i
            const style = {
              fontWeight: currentRefinement === page ? "bold" : "",
            }
            console.log({ i })
            console.log({ currentRefinement })
            return (
              <li key={i}>
                <a
                  className={`pagination-link ${
                    currentRefinement === page ? "is-current" : ""
                  }`}
                  href={createURL(page)}
                  style={style}
                  onClick={event => {
                    event.preventDefault()
                    refine(page)
                  }}
                >
                  {page}
                </a>
              </li>
            )
          })}
        </ul> */}
      </nav>
    ) : (
      ""
    )
  }
  const CustomPagination = connectPagination(Pagination)
  const CustomHits = connectHits(showDesigners)
  const VirtualRefinementList = connectRefinementList(() => null)
  return (
    <div className="columns" style={{ marginTop: "40px" }}>
      <div className="column is-1-desktop is-1-tablet is-0-mobile "></div>
      <div className="column is-10-desktop is-10-tablet is-12-mobile">
        <div className="pat-page-title">
          <p>
            Plan a trip to {pageTitle}
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </p>
        </div>
        <div className="pta-hero">
          <div className="pta-hero-text">
            {/* <p>
                Plan a trip to {pageTitle}
                <span
                  style={{
                    color: "#fec600",
                  }}
                >
                  .
                </span>
              </p> */}
          </div>
          <LazyLoadImage
            effect="blur"
            src={heroImageDetails.url}
            alt={heroImageDetails.alt}
          ></LazyLoadImage>
        </div>
        <div className="pta-destination-info">
          <RichText render={destinationInfo} />
        </div>

        {dbContext && (
          <FetchAsyncDesigners
            dbContext={dbContext}
            destination={pageTitle}
            destination_country={destination_country}
          />
        )}
        {/* <InstantSearch
              refresh={true}
              indexName="designers"
              searchClient={searchClient}
            >
              <Configure
                facetFilters={"countriesForItineraries.value"}
                props={destination_country}
                hitsPerPage={5}
              />
              <div className="columns">
                <div className="column is-12-desktop is-9-tablet ">
                  <div
                    className="columns is-narrow"
                    style={{ marginTop: "20px" }}
                  >
                    <CustomHits />
                  </div>
                </div>
              </div>
            </InstantSearch> */}

        {showTopDestination() ? (
          <div className="pta-things-to-do-container">
            {" "}
            {showDestinations()}
          </div>
        ) : (
          <div className="pta-things-to-do-container">
            {" "}
            {/* <p className="pat-section-header">
                Top things to do in {pageTitle}
              </p> */}
            {showThingsTodo()}
          </div>
        )}
        <div className="pta-things-to-do-container">
          {" "}
          {/* <p className="pat-section-header">Nearby Destinations</p> */}
          <div className=" is-hidden-mobile">{showDestinationsNearby()}</div>
          <div className=" is-hidden-desktop">
            {showDestinationsNearbyCarousel()}
          </div>
        </div>
        <div className="pta-things-to-do-container">
          <GhostPostFetcher tag={getUID()} />
        </div>

        <section
          className="section is-medium gradient-shape"
          style={{
            borderRadius: "0px",
            minHeight: "100vh",
            paddingLeft: "0px",
            paddingRight: "0px",
            padding: "0px",
          }}
        >
          <div
            style={{
              maxWidth: "75px",
              // margin: "0px auto",
              margin: "40px auto",
            }}
          >
            <img src={wiggle} alt="" />
          </div>
          <div className="pat-page-footer">
            <p>
              Ready to get a custom travel plan to {pageTitle} built from
              Scratch?
            </p>

            <a
              className="button is-warning"
              style={{ maxWidth: "200px" }}
              onClick={e => {
                if (authContext) navigate("/app/dashboard")
                else {
                  // analytics.logEvent("hero_signup_click")
                  navigate("/signup-as-traveler")
                }
              }}
            >
              Get Started
            </a>
          </div>

          <div className="container" id="our-approach">
            <div
              className="columns is-vcentered "
              style={{ textAlign: "center" }}
            >
              <div className="column is-1-desktop"></div>
              <div className="column is-5-desktop is-5-tablet" style={{}}>
                <div
                  style={{
                    marginBottom: "40px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      lineHeight: "1.7",
                      marginTop: "15px",
                      // textTransform: "uppercase",
                      textAlign: "center",
                      color: "#fec600",
                      // marginBottom: "20px",
                    }}
                  >
                    Here's how it all works
                  </span>
                </div>
                <div
                  style={{
                    maxWidth: "75px",
                    margin: "0 auto",
                    // marginTop: "50px",
                    marginBottom: "7px",
                  }}
                >
                  <LazyLoadImage
                    src={plan}
                    alt="create a travel plan design job"
                    effect="blur"
                  />
                </div>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    marginBottom: "10px",
                  }}
                  className={"gradient-card"}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    1. Create a design job
                  </span>{" "}
                </h4>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginBottom: "10px",
                    lineHeight: "1.8",
                  }}
                >
                  You begin by creating a trip design job and provide your
                  preferences and interests. These would be all the things that
                  you would <strong>personally consider necessary</strong> when
                  you are planning a trip.
                </p>

                <div
                  style={{
                    maxWidth: "75px",
                    margin: "0 auto",
                    marginTop: "50px",
                    marginBottom: "7px",
                  }}
                >
                  <LazyLoadImage
                    src={select}
                    alt="pick your travel designer"
                    effect="blur"
                  />
                </div>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    marginBottom: "10px",
                  }}
                  className={"gradient-card"}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    2. Pick a travel designer
                  </span>{" "}
                </h4>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginBottom: "10px",
                    lineHeight: "1.8",
                  }}
                >
                  Pick a personal travel plan designer to customize your trip.
                  You will have <strong>unlimited correspondence</strong> with
                  your designer to expand further or tweak your requirements.
                </p>
                {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>3</p> */}
                {/* <div style={{ maxWidth: "80px", margin: "0 auto" }}>
                <img src={three} alt="" />
              </div> */}
                <div
                  style={{
                    maxWidth: "75px",
                    margin: "0 auto",
                    marginTop: "50px",
                    marginBottom: "7px",
                  }}
                >
                  <LazyLoadImage
                    src={work}
                    alt="trip designer will do all the required travel research"
                    effect="blur"
                  />
                </div>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    marginBottom: "10px",
                  }}
                  className={"gradient-card"}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    3. Sit back & relax
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        display: "block",
                      }}
                    >
                      We'll do all the hard work
                    </span>
                  </span>{" "}
                </h4>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginBottom: "10px",
                    lineHeight: "1.8",
                  }}
                >
                  The designer will then do all the required research based on
                  your preferences and craft you a personalized travel plan. You
                  get to review the plan, and you{" "}
                  <strong>
                    can request changes until you are completely satisfied
                  </strong>
                  .<br></br>
                  <br></br>
                  <span
                    style={{
                      // textTransform: "uppercase",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Well,{" "}
                    <span
                      style={{
                        textDecorationColor: "#fec600",
                        // textDecorationLine: "underline",
                        /* text-decoration-style: wavy; */
                      }}
                    >
                      that's it!
                    </span>{" "}
                  </span>
                </p>
              </div>
              {/* <div className="column is-0-desktop is-1-tablet"></div> */}
              {/* <button onClick={methodDoesNotExist}>Break the world</button> */}
              <div className="column is-5-desktop" style={{ opacity: "1" }}>
                <div
                  // className="box price-box gradient1"
                  className="box price-box"
                  style={{
                    textAlign: "center",

                    // height: "520px",
                    margin: "0 auto",

                    //marginTop: "-200px",
                  }}
                >
                  <div style={{ display: "", justifyContent: "center" }}>
                    {/* <img style={{ maxWidth: "80px" }} src={relax}></img> */}
                    <div
                      style={{
                        lineHeight: "1",
                        textAlign: "left",
                        width: "245px",
                        margin: "0px auto",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                          // marginTop: "10px",
                          // textTransform: "uppercase",
                        }}
                      >
                        Travel{" "}
                        <span
                          style={{
                            fontSize: "36px",
                            fontWeight: "900",
                            // textDecoration: "underline",
                            textTransform: "uppercase",
                            color: "rgba(74,74,74,0.8)",
                          }}
                        >
                          <br></br>stress free
                          <span
                            style={{
                              fontSize: "38px",
                              fontWeight: "900",
                              // textDecoration: "underline",
                              textTransform: "uppercase",
                              color: "#fec600",
                            }}
                          >
                            .
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "rgba(74,74,74,0.8)",
                    }}
                  >
                    <p className="price-card-dollar-sign">$</p>
                    <p className="price-card-dollar-value">25</p>
                    <p className="price-card-dollar-trip-text">/day</p>
                  </div>
                  <div style={{ display: "", justifyContent: "center" }}>
                    {/* <img style={{ maxWidth: "80px" }} src={relax}></img> */}
                    <p
                      className="price-card-dollar-disclaimer-text"
                      style={{ letterSpacing: "0.5px" }}
                    >
                      (up to 14 days)
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {/* <img style={{ maxWidth: "80px" }} src={relax}></img> */}
                    <img
                      src={wiggle}
                      alt=""
                      style={{ width: "50px", marginRight: "10px" }}
                    />
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        // marginTop: "30px",
                        // textTransform: "uppercase",
                        // textDecoration: "underline",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                      }}
                    >
                      YOU GET
                    </p>
                    <img
                      src={wiggle}
                      alt=""
                      style={{ width: "50px", marginLeft: "10px" }}
                    />
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                      textAlign: "left",
                    }}
                    className="container price-card-items"
                  >
                    <ul style={{ marginBottom: "40px" }}>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "left",
                          marginBottom: "18px",
                          marginLeft: "33px",
                          textIndent: "-16px",
                        }}
                      >
                        {/* <i
                        className="fal fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                        <p
                          className=""
                          style={{ fontSize: "16px", marginLeft: "0px" }}
                        >
                          <i
                            className="far fa-check fa-lg"
                            style={{
                              color: "#fec600",
                              marginRight: "7px",
                              marginLeft: "2px",
                            }}
                          ></i>
                          Custom crafted itinerary that includes list of
                          activities & restaurant recommendations.
                        </p>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "left",
                          marginBottom: "18px",
                          marginLeft: "33px",
                          textIndent: "-16px",
                        }}
                      >
                        {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                        <p
                          className=""
                          style={{ fontSize: "16px", marginLeft: "0px" }}
                        >
                          <i
                            className="far fa-check fa-lg"
                            style={{
                              color: "#fec600",
                              marginRight: "7px",
                              marginLeft: "2px",
                            }}
                          ></i>
                          Unlimited chat with your travel plan designer
                        </p>
                      </li>

                      <li
                        style={{
                          display: "flex",
                          alignItems: "left",
                          marginBottom: "18px",
                          marginLeft: "33px",
                          textIndent: "-16px",
                        }}
                      >
                        {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                        <p
                          className=""
                          style={{
                            fontSize: "16px",
                            marginLeft: "0px",
                            marginLeft: "2px",
                          }}
                        >
                          <i
                            className="far fa-check fa-lg"
                            style={{
                              color: "#fec600",
                              marginRight: "7px",
                              marginLeft: "2px",
                            }}
                          ></i>
                          Accommodation recommendations
                        </p>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "left",
                          marginBottom: "18px",
                          marginLeft: "33px",
                          textIndent: "-16px",
                        }}
                      >
                        {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                        <p
                          className=""
                          style={{ fontSize: "16px", marginLeft: "0px" }}
                        >
                          <i
                            className="far fa-check fa-lg"
                            style={{
                              color: "#fec600",
                              marginRight: "7px",
                              marginLeft: "2px",
                            }}
                          ></i>
                          Local travel tips
                        </p>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "left",
                          marginBottom: "18px",
                          marginLeft: "33px",
                          textIndent: "-16px",
                        }}
                      >
                        {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                        <p
                          className=""
                          style={{ fontSize: "16px", marginLeft: "0px" }}
                        >
                          <i
                            className="far fa-check fa-lg"
                            style={{
                              color: "#fec600",
                              marginRight: "7px",
                              marginLeft: "2px",
                            }}
                          ></i>
                          Travel checklist
                        </p>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "left",
                          marginBottom: "18px",
                          marginLeft: "33px",
                          textIndent: "-16px",
                        }}
                      >
                        {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                        <p
                          className=""
                          style={{ fontSize: "16px", marginLeft: "0px" }}
                        >
                          <i
                            className="far fa-check fa-lg"
                            style={{
                              color: "#fec600",
                              marginRight: "7px",
                              marginLeft: "2px",
                            }}
                          ></i>
                          Unlimited revisions of your itinerary
                        </p>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "left",
                          marginBottom: "18px",
                          marginLeft: "33px",
                          textIndent: "-16px",
                        }}
                      >
                        {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                        <p
                          className=""
                          style={{ fontSize: "16px", marginLeft: "0px" }}
                        >
                          <i
                            className="far fa-check fa-lg"
                            style={{
                              color: "#fec600",
                              marginRight: "7px",
                              marginLeft: "2px",
                            }}
                          ></i>
                          An offline copy of your travel plan
                        </p>
                      </li>
                    </ul>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <a
                        // onClick={e => {
                        //   // if (authContext) navigate("/app/dashboard")
                        //   // else navigate("/signup-as-traveler")
                        //   // analytics.logEvent("viewed_sample_travelplan")
                        //   navigate("/app/sampletravelplan/")
                        // }}
                        href="/sample-travel-plan"
                        style={{
                          cursor: "pointer",
                          textAlign: "center",
                          // padding: "10px",
                          // margin: "15px",
                          // border: "1px solid #ebebeb",
                          borderRadius: "14px",
                          // maxWidth: "500px",
                          // backgroundColor: "#ebebeb",
                        }}
                      >
                        <p
                          style={{
                            textTransform: "uppercase",
                            // fontSize: "16px",
                            marginBottom: "20px",
                            // textDecorationColor: "#fec600",
                            // textDecoration: "underline",
                            fontWeight: "600",
                            maxWidth: "250px",
                            borderRadius: "6px",
                          }}
                          className="button is-warning is-small"
                        >
                          View a sample travel plan
                        </p>
                        <LazyLoadImage
                          src={paris}
                          alt="paris sample travel itinerary"
                          effect="blur"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-1-desktop"></div>
            </div>
          </div>
          <div
            style={{
              maxWidth: "400px",
              margin: "30px auto",
              marginTop: "80px",
              display: "none",
            }}
          >
            <button
              // className="button is-medium is-warning is-inverted brand-shadow"
              className="button is-medium is-warning brand-shadow"
              style={{ width: "100%" }}
              onClick={e => {
                if (authContext) navigate("/app/dashboard")
                else navigate("/signup-as-traveler")
              }}
            >
              Get started
            </button>
            {/* {showRightContent()} */}
          </div>
          <div
            style={{
              maxWidth: "75px",
              // margin: "0px auto",
              margin: "40px auto",
            }}
          >
            <img src={wiggle} alt="" />
          </div>
        </section>

        {/* {showSliceType("top_things_to_do")}
          {showSliceType("destinations_nearby")} */}
      </div>
      <div
        className="column is-1-desktop is-1-tablet is-0-mobile"
        style={{ marginBottom: "60px" }}
      ></div>
    </div>
  )
}
export const props = graphql`
  query destinationprops($url: String) {
    allPrismicPlanATripTemplate(filter: { url: { eq: $url } }) {
      edges {
        node {
          data {
            destination_type
            hero_image {
              alt
              url
            }
            destination_country
            destination_state
            destination_info {
              raw
              html
              text
            }
            page_title {
              html
              raw
              text
            }
            body {
              ... on PrismicPlanATripTemplateDataBodyTopDestinations {
                id
                slice_type
                items {
                  destination_url {
                    document {
                      ... on PrismicPlanATripTemplate {
                        url
                      }
                    }
                  }
                  destination_image {
                    url
                    alt
                  }
                  destination_name {
                    text
                    html
                    raw
                  }
                }
              }
              ... on PrismicPlanATripTemplateDataBodyDestinationsNearby {
                id
                slice_type
                items {
                  destination_url {
                    document {
                      ... on PrismicPlanATripTemplate {
                        url
                      }
                    }
                  }
                  destination_image {
                    alt
                    url
                  }
                  destination_name {
                    text
                  }
                }
              }
              ... on PrismicPlanATripTemplateDataBodyTopThingsToDo {
                id
                items {
                  thing_to_do_image {
                    url
                    alt
                  }
                  to_do_description {
                    text
                    html
                    raw
                  }
                  to_do_title {
                    text
                    html
                    raw
                  }
                }
                slice_type
              }
            }
          }
          uid
          url
          id
        }
      }
    }
  }
`
const PlanATrip = props => {
  let pageTitle = titleCase(
    props.data.allPrismicPlanATripTemplate.edges[0].node.data.page_title.text
  )
  return (
    <Layout>
      <SEO
        pageTitle={`${pageTitle}`}
        description={`Plan a trip to ${pageTitle}`}
      />
      <PlanATripBase state={props} />
    </Layout>
  )
}

export default PlanATrip
