import React from "react"
import { dottedText } from "../../utility/general"
import "./DesignerCard.scss"
import { LazyLoadImage } from "react-lazy-load-image-component"
const PATDesignerCard = ({
  backgroundImage,
  profileImage,
  name,
  text,
  id,
  uId,
}) => {
  return (
    <div id={id} key={id} className="pat-designer-card-container" style={{}}>
      <div
        className="media"
        style={{
          maxWidth: "160px",
          margin: "0 auto",
          // marginTop: "-88px",
        }}
      >
        <figure className="image">
          <LazyLoadImage
            style={{
              border: "3px solid #fec600",
              width: "160px",
              padding: "3px",
            }}
            className=" is-rounded brand-shadow"
            src={profileImage}
            alt="Placeholder image"
            effect="blur"
          />
        </figure>
      </div>
      <div className="media" style={{ borderTop: "none", marginTop: "0px" }}>
        <div
          className="media-content"
          style={{
            marginTop: "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
              color: "#4a4a4a",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
              // marginTop: "-15px",
            }}
          >
            {name}
          </p>
          <p
            style={{
              marginTop: "4px",
              textAlign: "center",
              textShadow: "none !important",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {dottedText(text.replace(/<[^>]+>/g, ""), 100)}{" "}
          </p>
          <span
            style={{
              fontSize: "13px",
              fontWeight: "500",
              textAlign: "center",
              marginTop: "7px",
              // textDecoration: "underline",
              // textDecorationColor: "white",
              // textDecorationThickness: "2px",
              // textTransform: "uppercase",
              letterSpacing: "0.5px",
              cursor: "pointer",
              borderBottom: "2px solid #fec600",
              padding: "2px",
              width: "80px",
            }}
            onClick={() => {
              window.open(`/app/userprofiles/${id}`, "_blank")
            }}
          >
            Read More{" "}
          </span>
        </div>
      </div>
    </div>
  )
}

export default PATDesignerCard
